<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-message-plus-outline
        </v-icon>
        New Message
      </h2>
      <br>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="11"
            >
              <v-textarea
                v-model="newMessage"
                filled
                label="New Message*"
                :counter="300"
                counter-value-color="red"
                :rules="[v => (v && v.length <= 300) || 'Message must be 300 characters or less']"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="sendSupportMessage()"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'SendSupportMessage',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        isFormValid: false,
        loading: false,
        newMessage: '',
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      sendSupportMessage () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('support/sendSupportMessage', {
          messageContent: {
            comment: this.newMessage,
          },
          ticketId: this.$route.params.ticketId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Message send successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('success');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #FF3700;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
