<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-chat-processing
      </v-icon>
      Support Ticket
    </h2>
    <v-card
      class="my-card-style"
    >
      <v-card
        color="white"
      >
        <div class="my-date-style">
          {{ currentDateTime }}
        </div>
        <br>
        <v-col
          class="text-right"
        >
          <v-btn
            color="#FF3700"
            @click="showMessageForm = true"
          >
            Send Message
          </v-btn>
        </v-col>
        <v-container
          v-if="isTicketDetailsFetched"
          fluid
        >
          <p
            class="my-p-style"
          >
            Ticket Name:-
            <font
              class="font-my-style"
            >
              {{ ticketDetails.name }}
            </font>
          </p>
          <p
            class="my-p-style"
          >
            Description:-
            <font
              class="font-my-style"
            >
              {{ ticketDetails.description }}
            </font>
          </p>
        </v-container>
      </v-card>
    </v-card>
    <v-card-text class="py-0">
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="(item, index) in supportTicketChats"
          :key="index"
          color="#FF3700"
          small
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <p
                class="my-p-style"
              >
                <font
                  class="font-my-style"
                >
                  {{ item.created_at | convertStringToLocalDatetime }}
                </font>
              </p>
            </v-col>
            <v-col>
              <font class="user-name">
                {{ item.message_type | typeFormat }}
              </font>
              <br>
              <div>
                <p
                  class="comment-style"
                >
                  {{ item.comment }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <centre-spinner
      :loading="loading"
    />
    <send-support-message
      v-if="showMessageForm"
      @closed="showMessageForm = false"
      @success="fetchSupportTicketChats"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SendSupportMessage from './SendSupportMessage.vue';

  export default {
    name: 'SupportTicketChats',
    components: {
      'centre-spinner': spinner,
      'send-support-message': SendSupportMessage,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      typeFormat (val) {
        if (val === 'author') {
          return 'Client*';
        } else if (val === 'support-team') {
          return 'Support Team';
        } else {
          return 'System Generated';
        }
      },
    },
    data: () => ({
      tab: null,
      currentDateTime: null,
      loading: false,
      showMessageForm: false,
    }),
    computed: {
      supportTicketChats () {
        const list = this.$store.getters['support/getSupportTicketChats'];
        const sortedList = list.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        return sortedList;
      },
      isChatsFetched () {
        if (this.supportTicketChats.length > 0) {
          return true;
        }
        return false;
      },
      ticketDetails () {
        return this.$store.getters['support/getTicketContent'];
      },
      isTicketDetailsFetched () {
        if (Object.keys(this.ticketDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      await this.fetchSupportTicketDetails();
      await this.fetchSupportTicketChats();
    },
    created () {
      this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
      setInterval(() => this.liveDateTime());
    },
    methods: {
      async fetchSupportTicketChats () {
        this.loading = true;
        await this.$store.dispatch('support/fetchSupportTicketChats', {
          ticketId: this.$route.params.ticketId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async fetchSupportTicketDetails () {
        this.loading = true;
        await this.$store.dispatch('support/fetchTicketContent', {
          ticketId: this.$route.params.ticketId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      liveDateTime () {
        this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
      },
      back () {
        this.$router.push({ name: 'Support Tickets' });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-date-style {
  float: left;
  margin-top: 10px;
  margin-left: 20px;
  color: #37474F;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
}
.comment-style {
  font-size: 16px;
  color: #37474F;
  margin-top:5px;
  font-family: 'Times New Roman', Times, serif;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.not-checked-out-style {
  font-weight: bold;
  font-size: 15px;
  color:#2E7D32;
}
.type-style {
  font-weight: bold;
  font-size: 15px;
  color: #FF3700;
  text-transform: capitalize;
}
.my-back-btn-style {
  margin-left: -20px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.user-name{
  font-weight: bold;
  font-size: 16px;
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
}
</style>
